let $title = document.querySelector("h1");
let $cursor = document.querySelector(".cursor");

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

document.body.addEventListener("mousemove", onMouseMove);

function onMouseMove(e) {
  gsap.to($cursor, {
    duration: 0.15,
    x: e.clientX - 20,
    y: e.clientY - 20,
  });

  let $buttons = document.querySelectorAll("a, .interactable");

  $buttons.forEach((button) => {
    button.addEventListener("mouseenter", () => {
      gsap.to($cursor, {
        duration: 0.15,
        scale: 0.5,
      });
    });
    button.addEventListener("mouseleave", () => {
      gsap.to($cursor, {
        duration: 0.15,
        scale: 1,
      });
    });
  });
}

//======>> 404 canva <<======//

if (document.documentElement.classList.contains("404")) {
  let $canvas = document.querySelector(".canva-404");

  let createGrid = function () {
    $canvas.innerHTML = "";

    let canvasWidth = window.innerWidth;
    let canvasHeight = window.innerHeight;

    let cellSize = 23;
    let cols = Math.floor(canvasWidth / cellSize);
    let rows = Math.floor(canvasHeight / cellSize + 15);

    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < cols; j++) {
        let item = document.createElement("div");
        item.classList.add("item");
        item.innerHTML = "404";
        item.style.left = j * cellSize + "px";
        item.style.top = (i * cellSize) / 1.5 + "px";
        item.style.width = cellSize + "px";
        item.style.height = cellSize + "px";
        item.style.textAlign = "center";

        item.addEventListener("mouseenter", () => {
          item.style.opacity = 1;
          item.style.scale = 1;

          setTimeout(() => {
            item.style.opacity = 0;
            item.style.scale = 0.5;
          }, 3000);
        });

        $canvas.appendChild(item);
      }
    }
  };

  createGrid();

  window.addEventListener("resize", createGrid);
}

//  __O
// / /\_
// _/\
//   /

//======>> Gallery interactions <<======//

document.addEventListener("DOMContentLoaded", () => {
  const $gallery = document.querySelector(".gallery__grid");
  const $galleryItems = document.querySelectorAll(".gallery__item");
  const $galleryFilters = document.querySelectorAll(".gallery__filter");

  // Fonction pour réinitialiser les éléments de la galerie
  function resetGalleryItems() {
    $galleryItems.forEach((item) => {
      item.style.display = "block"; // Réinitialiser l'affichage à "block" pour tous les éléments
    });
  }

  // Fonction pour réinitialiser les classes de la galerie
  function resetGalleryClasses() {
    $gallery.classList.remove(
      "gallery__3d",
      "gallery__web",
      "gallery__motion",
      "gallery__graph",
      "gallery__video"
    );
  }

  $galleryFilters.forEach((filter) => {
    filter.addEventListener("click", () => {
      // Réinitialiser les classes de la galerie et les éléments
      resetGalleryItems();
      resetGalleryClasses();

      let filterClass = "";

      // Déterminer la classe du filtre sélectionné
      if (filter.classList.contains("filter__3d")) {
        filterClass = "filter__3d";
        console.log("3d");
      } else if (filter.classList.contains("filter__web")) {
        filterClass = "filter__web";
        console.log("web");
      } else if (filter.classList.contains("filter__all")) {
        filterClass = ""; // Pas de classe spécifique pour "all"
        console.log("all");
      } else if (filter.classList.contains("filter__graph")) {
        filterClass = "filter__graph";
        console.log("graph");
      } else if (filter.classList.contains("filter__video")) {
        filterClass = "filter__video";
        console.log("video");
      }

      // Appliquer la classe de filtre à la galerie si nécessaire
      if (filterClass) {
        $gallery.classList.add(filterClass);
      }

      // Masquer les éléments qui ne correspondent pas au filtre sélectionné
      $galleryItems.forEach((item) => {
        if (filterClass && !item.classList.contains(filterClass)) {
          item.style.display = "none";
        }
      });
    });
  });

  $galleryItems.forEach((item) => {
    item.addEventListener(
      "click",
      () => {
        // If the item has the "link" class, redirect and do nothing else
        if (item.classList.contains("archive")) {
          window.location.href = "https://archive.a-frank.dev/";
        } else if (item.classList.contains("alvin")) {
          window.location.href = "https://workinprogress.a-frank.dev/alvin/";
        } else if (item.classList.contains("origin")) {
          window.location.href = "https://origin-jumpworks.space/";
        } else if (item.classList.contains("viriskha")) {
          window.location.href = "https://soundcloud.com/viriskha_off/";
        } else {
          // Create the expanded view for non-"link" items
          let focusItem = document.createElement("div");
          focusItem.classList.add("gallery__item--active");

          let src = item.getAttribute("src");
          let srcset = item.getAttribute("srcset");
          let alt = item.getAttribute("alt");

          // Check if the clicked item is a video or image
          let focusElement;
          if (item.tagName.toLowerCase() === "img") {
            // Create an image element for the expanded view
            focusElement = document.createElement("img");
            focusElement.setAttribute("src", src);
            if (srcset) focusElement.setAttribute("srcset", srcset);
            focusElement.setAttribute("alt", alt);
          } else if (item.tagName.toLowerCase() === "video") {
            console.log("video");

            // Create a video element for the expanded view
            focusElement = document.createElement("video");
            focusElement.setAttribute("src", src);
            focusElement.setAttribute("alt", alt);
            focusElement.setAttribute("controls", true); // Add video controls
            focusElement.setAttribute("autoplay", true); // Optionally autoplay
            focusElement.setAttribute("loop", true); // Optionally loop
            focusElement.setAttribute("muted", true); // Optionally mute
          }

          // Create description and close button
          let focusDescription = document.createElement("p");
          focusDescription.textContent = alt;

          let closeBtn = document.createElement("button");
          closeBtn.textContent = "×";
          closeBtn.classList.add("interactable");

          // Append elements
          focusItem.appendChild(focusElement);
          focusItem.appendChild(focusDescription);
          focusItem.appendChild(closeBtn);
          document.body.appendChild(focusItem);

          // Disable body scrolling while the gallery item is expanded
          document.body.style.overflow = "hidden";

          // Handle closing the expanded view
          closeBtn.addEventListener("click", () => {
            focusItem.remove();
            document.body.style.overflow = "auto"; // Restore scrolling
          });
        }
      },
      { once: true }
    ); // Ensures the event listener is only triggered once per item
  });
});

//======>> Anti code stealer <<======//

const detectDevTools = () => {
  const isDevToolsOpened = /./;
  isDevToolsOpened.toString = () => {
    console.log(
      "💀\n≤))≥\n_||_ Wanna see the backstages ?\n\n Try by understand my code haha"
    );
    return "Looking something in my code...";
  };

  console.log(isDevToolsOpened);
};

detectDevTools();
